import { render, staticRenderFns } from "./SGSST.vue?vue&type=template&id=1f955830&scoped=true"
import script from "./SGSST.vue?vue&type=script&lang=js"
export * from "./SGSST.vue?vue&type=script&lang=js"
import style0 from "./SGSST.vue?vue&type=style&index=0&id=1f955830&prod&scoped=true&lang=css"
import style1 from "./SGSST.vue?vue&type=style&index=1&id=1f955830&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f955830",
  null
  
)

export default component.exports